:root {
	--header-height: 80px;
	--footer-height: 80px;
	--background: #000;
	--background-accent: #0e0f25;
	--foreground: #fff;
	--card-background: #181818;
	--card-hover: #1f1f1f;
	--divider: #2d282d;
	--divider-accent: #2d2d7f;
	--subtext: #c4c4c4;
	--accent: #4a4aa0;
	--accent-active: #7777cc;
	--accent-hover: #6a6ac0;
	--warning: orange;
}


.container{
	background-color: #000;
	color: #fff;
	padding-top: var(--header-height); 
	min-height: calc(100vh - (var(--header-height) + var(--footer-height)));
}

.container a{
	color: var(--accent);
}

.container a:hover{
	color: var(--accent-hover);
	cursor: pointer;
}

.bold{
	font-weight: bolder;
}

.big{
	font-size: 22px;
}

.medium{
	font-size: 19px;
}

.small{
	font-size: 12px;
}

.subtext{
	color: var(--subtext);
}

.noMargin{
	margin-block-start: 0px;
}

.thin{
	font-weight: 100;
}

@media only screen and (max-width: 1200px){
	:root{
		--header-height: 60px;
	}
}