
.homeContainer{
	background-color: var(--background);
}

.homeBox{
	padding: 30px 0 50px 90px;
	display: flex;
	flex-direction: column;
	min-height: 420px;
	max-height: 845px;
	justify-content: space-between;
	max-width: 50vw;
}

.homeBox h1{
	margin: 25px 0 0px 0;
	font-size: 48px;
	font-weight: 500;
}

.separator{
	margin: 2px 0px 2px 0px;
	width: 222px;
	height: 1px;
	background-color: var(--subtext);
	opacity: 0.5;
}

.homeContainer .subHeaderLabel{
	margin-block-start: 0.2em;
	font-size: 30px;
	max-width: 535px;
	font-weight: 100;
}

.homeContainer .logoContainer{
	top: var(--header-height)
}

.homeContainer .downloadButtonContainer{
	width: 222px;
	display: flex;
	justify-content: center;
	flex-direction: column;
}

a.microsoftStoreImg{
	width: 200px;
	margin: 5px 0;
	border: 1px solid var(--divider);
	border-radius: 3px;
	padding: 15px 10px;
	justify-content: center;
	display: flex;
	align-items: center;
	color: var(--foreground);
	background-color: var(--accent);
	text-decoration: none;
}
a.microsoftStoreImg:hover{
	cursor: pointer;
	box-shadow: 1px 1px 25px #aaaaaa22;
	background-color: var(--accent-hover);
	color: var(--foreground);
}

a.microsoftStoreImg:active{
	box-shadow: 1px 1px 20px #aaaaaa55;
	background-color: var(--accent-active);
}

.slideshowContainer{
	background-color: var(--accent);
	display:flex;
	padding: 20px;
	flex-direction: column;
	align-items: center;
	overflow: hidden;
}

.slideshowContainer .description{
	max-width: 30vw;
	margin: 50px 0px;
	text-align: center;
}

.slideshowContainer .description p{
	margin: 5px 0px;
}

.imageBackground{
	background-color: var(--background-accent);
}

.imgContainer{
	height: auto;
	margin: 0px 15px 100px 15px;
	align-items: center;
	justify-content: center;
	display: flex;
}

.synthImg{
	position: absolute;
	left:55vw;
	width: 39vw;
	max-width: 800px;
	display: block;
}

.homeContainer .headerCallToActionContainer{
	display: flex;
	width: fit-content;
	flex-direction: column;
}

.featuresContainer{
	border-width: 0;
	border-top-width: 1px;
	border-bottom-width: 1px;
	border-color: var(--divider);
	border-style: solid;
	padding: 80px 20px 80px 20px;
	background-color: var(--card-background);
	display: flex;
	flex-direction: column;
	align-items: center;
}

.featuresDescriptionsContainer{
	display: flex;
	padding-bottom: 20px;
}

.featureContainer{
	margin: 0px 40px;
	min-width: 310px;
	max-width: 420px;
}

.center{
	text-align: center;
}

.homeContainer .labelContainer{
	display: flex;
	flex-direction: row;
	justify-content: space-between;
}

.slide-image-container{
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.mobile-slide-container{
	display: none;
}
.slide-container {
    margin: 30px 0px;
	display: flex;
	justify-content: center;
	align-items: center;
}
.slideTitle{
	font-size: 2em;
	color: var(--subtext);
}
.slideCaption{
	margin: 10px 0px;
	padding: 0px 10px;
	max-width: calc(90vw - 120px);
}
.slideImg{
	max-height: 78vh;
	width: auto;
	max-width: calc(90vw - 120px);
	margin: 0px 10px;
}  

@media only screen and (max-width: 1200px) {
	.slideshowContainer .description{
		max-width: fit-content;
		padding: 10px;
	}

	.logoContainer .description{
		display: flex;
		justify-content: center;
	}

	.slideshowContainer{
		padding: 20px 0px;
	}

	.imgContainer{
		width: 100vw;
		height: auto;
		margin: 40px 0px;
		display: flex;
		align-items: center;
		flex-direction: column;
	}

	.synthImg{
		display: none;
	}

	.homeBox h1{
		font-size: 42px;
	}

	.separator{
		align-self: center;
		width: 85%;
		max-width: 200px;
	}
	
	.homeBox{
		padding: 0px 20px 20px 20px;
		display: flex;
		flex-direction: column;
		max-width: 100vw;
		position: relative;
		text-align: center;
		align-items: center;
	}
	.featuresDescriptionsContainer{
		display: block;
		margin-bottom: 0;
	}
	.featureContainer{
		margin: 0px 0px 40px 0px;
		width: auto;
		padding: 5px;
		max-width: 600px;
		text-align: center;
	}

	.homeContainer .logoContainer{
		position: relative;
		top: 0;
	}
	
	.slideshowContainer .description{
		margin: 20px 0px;

	}

	.homeContainer .headerCallToActionContainer{
		align-items: center;
	}

	.homeContainer .subHeaderLabel{
		font-size: 26px;
		max-width: none;
	}
	
	.slide-container {
		display: none;
	}
	.slideTitle{
		font-size: 2em;
		color: var(--subtext);
		display: block;
		text-align: center;
	}
	.slideCaption{
		margin: 10px 0px;
		padding: 0px 10px;
		width: 90vw;
		max-width: 90vw;
		display: block;
	}
	.slideImg{
		max-height: 82vh;
		width: 95vw;
		max-width: 95vw;
		margin: 0px 10px;
	}
	.mobile-slide-container{
		display: block;
	}
  }