.headerContainer{
	height: var(--header-height);
	background-color: #000;
	display: flex;
	align-items: center;
	justify-content: start;
	color: #fff;
	position: fixed;
	top: 0;
	width: 100%;
	z-index: 9;
	padding-left: 100px;
}
.headerContainer a{
	margin: 0px 20px;
	font-size: 22px;
	padding: 15px 5px;
	border-radius: 3px;
	width: 120px;
	justify-content: center;
	align-items: center;
	display: flex;
}

.headerContainer span{
	font-size: 30px;
	margin-right: 30px;
}

.headerContainer span:hover{
	cursor: pointer;
}

.headerContainer a.active{
	background-color: var(--card-background);
}
.headerContainer .logoImg{
	width: 20px;
	height: 20px;
	margin-right: 5px;
	justify-content: flex-end;
	align-items: flex-end;
}
.headerContainer a:hover{
	cursor: pointer;
	text-decoration: underline;
}

.headerContainer .logoImg{
	width: 20px;
	height: 20px;
	margin-right: 8px;
}

.headerContainer .homeIcon{
	padding: 10px 5px;
}

@media only screen and (max-width: 1200px){
	.headerContainer{
		justify-content: space-around;
		padding-left: 0;
	}

	.headerContainer a{
		padding: 10px 5px;
	}
}