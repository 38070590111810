.footerContainer{
	height: var(--footer-height);
	background-color: #000;
	display: flex;
	flex-direction: row;
	justify-content: space-evenly;
	color: #fff;
	padding: 20px;
}

.footerContainer .footerItem{
	display: flex;
	flex-direction: column;
	justify-content: space-evenly;
}


.footerContainer a:hover{
	cursor: pointer;
	text-decoration: underline;
}

.footerContainer a{
	color: var(--accent);
}

.footerContainer a:hover{
	color: var(--accent-hover);
	cursor: pointer;
}