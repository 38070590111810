
.docsContainer{
	display: flex;
	overflow: auto;
}

.docsContent{
	width: 100%;
	padding: 20px;
	color: var(--subtext);
	background-color: var(--card-background);
	display: flex;
	flex-direction: column;
	align-items: center;
	min-height: calc(100vh - (var(--header-height) + 40px));
}

.docsContainer .docsInnerContent{
	max-width: 1000px;
}

.docsContent h3{
	align-self: flex-start;
	margin-block-end: 0px;
	margin-block-start: 0px;
}

.docsContent .content{
	margin-bottom: 80px;
	padding: 0px 20px;
}

.docsContent .content div{
	margin: 5px 0;
}

.marginLeft10{
	margin-left: 10px;
}

.docsContent .content li div{
	margin: 5px 0;
}

.docsContainer .content p{
	margin-left: 20px;
	margin-right: 20px;
}

.docsContainer .content li p{
	margin-left: 5px;
	margin-right: 5px;
}

.indented{
	margin-left: 20px;
}

.docsContent li{
	margin: 5px 0px 5px 0px;
}

.docsContent .msgItalics{
	background-color: #ffffff0a;
	padding: 5px 10px;
	border-radius: 3px;
	font-size: 14px;
}

.docsContent .row{
	display: flex;
	flex-direction: row;
	align-items: center;
}

.docsContent .connectingLi{
	justify-content: space-between;
	max-width: 500px;
}

.docsContent .connectingLiLabel{
	display: flex;
	flex: 1;
}

.docsContent .connectingLiValue{
	display: flex;
	width: 300px;
}

.docsContent .connectionContainer{
	padding-bottom: 30px;
}

.docsContent .connectionContainer.last{
	padding-bottom: 0;
}

.infoLabel{
	color: var(--accent);
}
.infoLabel:hover{
	color: var(--accent-hover);
	cursor: pointer;
}
.infoLabel:active{
	color: var(--accent-active);
}

.docsContent .docItem{
	width: 1000px;
}

.docsContent .docItem.opened{
	border: 1px solid var(--accent);
	border-radius: 3px;
	margin-bottom: 50px;
}

.docsContent .labelContainer{
	display: flex;
	flex-direction: row;
	padding: 20px;
	align-items: center;
	justify-content: center;
	margin-bottom: 50px;
	border-radius: 5px;
}
.docsContent .labelContainer.opened{
	background-color: var(--card-hover);
}
.docsContent .labelContainer:hover{
	cursor: pointer;
	background-color: var(--card-hover);
	border-radius: 3px;
}

.docsContent .minimizeIcon{
	width: 8px;
	height: 8px;
	filter: invert(100%);
	margin-right: 10px;
}

.docsContent .addIcon{
	width: 8px;
	height: 8px;
	filter: invert(100%);
	margin-right: 10px;
}

.docsContent .chevronUpImg{
	width: 11px;
	height: 11px;
	filter: contrast(0);
	margin: 0px 8px;
}

@media only screen and (max-width: 1200px) {
	.docsNav{
		display: none;
	}
	.docsContent .content{
		max-width: calc(100vw - 65px);
	}

	.docsContent .docItem{
		width: 90vw;
	}

	.docsContent .connectingLi{
		justify-content: space-between;
		max-width: calc(100vw - 65px);
		padding-left: 10px;
	}

	.docsContent .connectingLiLabel{
		flex: 1;
	}

	.docsContent .connectingLiValue{
		flex: 1;
	}

	.docsContent ol {
		padding-inline-start: 20px;
	}

	.docsContent ol li ul{
		padding-inline-start: 0;
		max-width: calc(100vw - 65px);
		margin: 0;
	}

}