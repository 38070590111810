.pageNotFound{
	display: flex;
	align-items: center;
	justify-content: center;
}

.pageNotFound .innerContainer{
	text-align: center;
}

.pageNotFound img{
	width: 400px;
}
