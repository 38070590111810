
.supportContainer{
	display: flex;
	color: var(--subtext);
	background-color: var(--card-background);
	flex-direction: column;
	align-items: center;
	min-height: calc(100vh - var(--header-height));
	width: 100%;
}

.supportContainer .content{
	margin: 0px 0px 100px 40px;
}

.supportNav{
	width: 300px;
	background-color: #121226;
	position: fixed;
	left: 0;
	top: var(--header-height);
	height: calc(100% - var(--header-height));
}

.supportNav .navItem{
	margin: 25px 20px;
	font-size: 18px;
}

.supportNav .navItem:hover{
	cursor: pointer;
	color: var(--subtext);
}

.supportContainer .warning{
	color: var(--warning);
}

.supportContainer .note{
	background-color: #FFA5001a;
	padding: 10px 15px;
	margin:  20px 0px 0px 0px;
	border-radius: 3px;
}

.supportContainer .note p{
	margin-left: 50px;
}

.supportContainer .note span{
	position: absolute;
	margin-top: 15px;
}

.supportContainer h5{
	color: var(--foreground);
}

.supportInnerContainer{
	padding: 20px;
	max-width: 60%;
}

.supportContainer li {
	margin-left: 10px;
}

.supportContainer .msgItalics{
	background-color: #ffffff0a;
	padding: 2px 5px;
	border-radius: 3px;
}

.supportContainer #requestSupportButton{
	padding: 10px;
	text-decoration: none;
	border: 1px solid var(--accent);
	border-radius: 3px;
}

.supportContainer #requestSupportButton:hover{
	color: var(--accent-hover);
	border-color: var(--accent-hover);
}

.supportContainer #requestSupportButton:active{
	border: 1px solid var(--accent);
	border-radius: 3px;
	color: var(--accent-active);
}

.supportContainer .infoLabel{
	margin-left: 15px;
	font-size: 14px;
}

@media only screen and (max-width: 1200px) {
	.supportContainer{
		display: block;
	}
}

@media only screen and (max-width: 1200px) {
	.supportNav{
		display: none;
	}
	.supportContainer .supportContainer{
		margin-left: 0;
	}
	.supportContainer .content{
		margin: 0px;
	}
	.supportContainer .supportContainer{
		width: auto;
	}
	.supportInnerContainer{
		max-width: 1200px;
	}

}